<template>
    <section id="login">
        <h1 class="mb-8">Login</h1>
        <v-form ref="form" @submit.prevent="validate">
            <v-text-field
                    v-model="form.email"
                    label="Email"
                    :rules="emailRules"
                    outlined
                    dense
            >
            </v-text-field>
            <v-text-field
                    v-model="form.password"
                    label="Password"
                    :rules="[v => !!v || 'Password is required']"
                    type="password"
                    outlined
                    dense
            >
            </v-text-field>
            <v-btn color="primary" :loading="loading" :disabled="loading" @click="login">
                submit
            </v-btn>
            <v-btn class="float-right" :loading="loading" :disabled="loading" @click="$router.push({name:'register'})">
                Register
            </v-btn>
        </v-form>
    </section>
</template>


<script>
    import {mapGetters} from "vuex";
    import auth from "../../../../api/modules/auth";
    import {EventBus} from "../../../../bootstrap/EventBus";

    export default {
        name: "Login",
        data: () => ({
            form: {
                email: null,
                password: null,
            },
            errors: null,
            loading: false,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
        }),
        computed: {
            ...mapGetters('auth', ['check'])
        },
        created: function () {
            if (this.check) {
                this.$router.push({name: 'dashboard'})
            }
        },
        methods: {
            login() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    auth.login(this.form).then(res => {
                        this.$store.dispatch("auth/saveAuthUser", res.data).then(() => {
                            this.$router.push({name: 'projects'})
                            this.loading = false;
                        });
                    }).catch(e => {
                        this.loading = false;
                        EventBus.$emit('submit-error', e);
                    });
                }
            },
            validate() {
                this.$refs.form.validate()
            },
        }
    }
</script>
